import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Checkbox, Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ChangeEvent, FC, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { useDynamicGroups } from '@/api/dynamic-groups/get'
import { EducationModule } from '@/api/education-modules/modules'
import { useGroups } from '@/api/groups/get'
import noGroupsAnimation from '@/assets/lottie/suspicion.json'
import useQueryParams from '@/common/hooks/useQueryParams'
import { theme } from '@/theme/theme'
import { Targets } from '@/types/campaigns'
import { RowDataItem, SortOrder } from '@/types/common'
import { Group } from '@/types/groups'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { styled } from '@mui/system'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import StatusFilter from '../StatusFilter/StatusFilter'
import Table, { TalbeColumn } from '../Table'
import InTableMessageWithAnimation from '../Tables/InTableMessageWithAnimation'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

type LaunchWizardGroupsTableProps = {
  handleAddGroups: (groupIds: string[], groups: Group[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  selectedGroups: string[]
  clientEnforceDomain: boolean
}

const INITIAL_QUERY_FILTERS = {
  status: ['active'],
  sort_by: 'name',
  sort_order: SortOrder.asc,
  skip: 0,
  limit: 10,
}

const LaunchWizardGroupsTable: FC<LaunchWizardGroupsTableProps> = ({
  handleAddGroups,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  selectedGroups,
  clientEnforceDomain,
}) => {
  const { t } = useTranslation()
  useDynamicGroups({ check_unverified_domain: true, limit: 25 }) // Call this to preload data

  const isPackage = assets && assets.length > 1

  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)
  const { data: queryData, isFetching } = useGroups({ ...queryParams, check_unverified_domain: true })
  const { results: groups, total } = queryData || { total: 0, results: [] }

  const handleFilterChange = useCallback((value: string) => {
    updateQueryParam({ search: value })
  }, [])

  const handleStatusChange = useCallback((value: string[]) => {
    updateQueryParam({ status: value })
  }, [])

  //TODO move this to BE
  const isDisabled = (row: RowDataItem) => {
    return row.user_count === 0
  }

  const handleSelecctAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = groups.filter((g) => !isDisabled(g))
      handleAddGroups(
        newSelected.map((g) => g._id),
        newSelected
      )
    } else {
      handleRemoveFromList(groups.map((g) => g._id))
    }
  }

  const rowClick = (row: RowDataItem) => {
    if (isDisabled(row)) return

    if (selectedGroups.includes(row._id)) {
      handleRemoveFromList(row._id)
    } else {
      handleAddGroups([row._id], [{ ...(row as Group) }])
    }
  }

  const tableColumns = useMemo<TalbeColumn[]>(() => {
    const columns = [
      {
        id: 'custom_checkbox',
        label: '',
        headerComponent: () => {
          const isAnyMemberInactive = groups.some((group: Group) => group.has_inactive_member)
          const isAnyUnverifiedDomain = groups.some(
            (group: Group) => group.has_unverified_domain && clientEnforceDomain
          )
          return (
            <Tooltip
              title={t('launchWizard.groupsTable.tooltips.containsWarnings')}
              placement={'right'}
              disableHoverListener={!(isAnyMemberInactive || isAnyUnverifiedDomain)}>
              <Checkbox
                color="primary"
                checked={groups.every((g) => selectedGroups.includes(g._id))}
                onChange={handleSelecctAll}
              />
            </Tooltip>
          )
        },
        component: (row: Group) => (
          <Tooltip title={<TableBodyTooltip group={row} />} placement={'right'} disableHoverListener={!isDisabled(row)}>
            <Checkbox
              color="primary"
              checked={selectedGroups.includes(row._id)}
              inputProps={{
                'aria-labelledby': `table-checkbox-${row._id}`,
              }}
            />
          </Tooltip>
        ),
      },
      {
        id: 'name',
        label: 'Name',
        sortable: true,
        component: (row: Group) => {
          //TODO Move group warning to BE
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))
          const onlyAssetsWithPhone = assets?.every((a) =>
            a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
          )
          row.warnings = {}

          if (row.has_inactive_member) {
            row.warnings.has_inactive_member = true
          }
          if (row.user_count === 0) {
            row.warnings.has_no_members = true
          }
          if (row.has_unverified_domain && hasEmailSimulation) {
            row.warnings.has_unverified_domain = true
          }
          if (row.has_member_with_missing_phone && onlyAssetsWithPhone) {
            row.warnings.has_missing_phone = true
          }

          return <TableCellNameWithWarnings row={row} />
        },
      },
      {
        id: 'user_count',
        label: t('users.groupsTable.userCount'),
        sortable: true,
      },
      {
        id: 'awareness_score',
        label: t('users.groupsTable.score'),
        sortable: true,
        component: (row: Group) => {
          return <HumanizedScore score={row.awareness_score} />
        },
      },
    ]

    if (isPackage) {
      columns.splice(3, 0, {
        id: 'distribution',
        label: t('users.groupsTable.distribution'),
        sortable: false, // optional but ts error?
        component: (row: Group) => (
          <DistributionSelect
            type="groups"
            onChange={handleDistributionTargetsChange}
            itemId={row._id}
            selectedItems={distributionTargets.groups}
            assets={assets}
            disabled={isDisabled(row)}
            disableUnverifiedDomain={row.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedGroups, groups, isPackage])

  return (
    <>
      <div style={{ marginBottom: 1, display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} />
        <StatusFilter onChange={handleStatusChange} values={queryParams.status} label="Status" />
      </div>
      <Paper sx={{ overflowX: 'scroll', 'scrollbar-width': 'thin' }}>
        <Table
          loading={isFetching}
          data={groups}
          total={total}
          inactiveRowCondition={isDisabled}
          customSelect
          queryParams={queryParams}
          updateQueryParam={updateQueryParam}
          columns={tableColumns}
          rowClick={rowClick}
          noResultsComponent={<NoResults />}
        />
      </Paper>
    </>
  )
}

const NoResults: FC = () => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation animation={noGroupsAnimation}>
      <p>{t('noResults.emptyGroup')}</p>
      <p>
        <Trans i18nKey={'noResults.goToGroup'} components={{ 1: <NavLink to="/recipients/groups" /> }} />
      </p>
    </InTableMessageWithAnimation>
  )
}

const TableCellNameWithWarnings: FC<{ row: Group }> = ({ row }) => {
  const warningLink = {
    has_inactive_member: '../../recipients/members',
    has_unverified_domain: '../../settings/allowlisting',
    has_no_members: '../../recipients/groups',
    has_missing_phone: '../../recipients/members',
  }

  return (
    <Box display="flex" textAlign="left">
      {row.name}

      {Object.keys(row?.warnings).map((key) => (
        <Tooltip
          title={
            <Trans
              i18nKey={`launchWizard.groupsTable.warnings.${key}`}
              components={{
                1: (
                  <Link
                    href={warningLink[key]}
                    target="_blank"
                    style={{
                      textDecoration: 'underline #D1F6FF',
                      color: '#D1F6FF',
                    }}
                  />
                ),
              }}
            />
          }
          placement={'right'}>
          <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
        </Tooltip>
      ))}
    </Box>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

// TODO Refactor This behaviour to be able to show more than one warning
const TableBodyTooltip: FC<{ group: Group }> = ({ group }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={
          group.has_inactive_member
            ? `launchWizard.groupsTable.warnings.has_inactive_member`
            : group.has_unverified_domain
            ? `launchWizard.groupsTable.warnings.has_unverified_domain`
            : group.user_count === 0
            ? `launchWizard.groupsTable.warnings.has_no_members`
            : ''
        }
        components={{
          1: (
            <Link
              href={
                group.has_inactive_member
                  ? '../../recipients/members'
                  : group.has_unverified_domain
                  ? '../../settings/allowlisting'
                  : group.user_count === 0
                  ? '../../recipients/groups'
                  : ''
              }
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default LaunchWizardGroupsTable
