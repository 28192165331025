import { useDynamicGroups } from '@/api/dynamic-groups/get'
import { EducationModule } from '@/api/education-modules/modules'
import useQueryParams from '@/common/hooks/useQueryParams'
import { theme } from '@/theme/theme'
import { Targets } from '@/types/campaigns'
import { RowDataItem, SortOrder } from '@/types/common'
import { DynamicGroup } from '@/types/dynamicGroups'
import { PhishingSimulation } from '@/types/phishingSimulations'
import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faInfoCircle, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Checkbox, Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { styled } from '@mui/system'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { ColumnInterface } from 'react-table'
import HumanizedScore from '../HumanizedScore/HumanizedScore'
import SimpleSearchBar from '../SimpleSearchBar/SimpleSearchBar'
import Table, { TalbeColumn } from '../Table'
import NoResults from '../Tables/NoResults'
import DistributionSelect from './DistibutionSelect'
import { RecepientsType } from './UserPicker'

const INITIAL_QUERY_FILTERS = {
  sort_by: 'first_name',
  sort_order: SortOrder.asc,
  search: '',
  limit: 25,
  skip: 0,
}

type LaunchWizardGroupsTableProps = {
  handleAddDynamicGroups: (groupIds: string[], groups: DynamicGroup[]) => void
  handleRemoveFromList: (id: string | string[]) => void
  handleDistributionTargetsChange: (type: RecepientsType, itemId: string, value: string) => void
  distributionTargets: Targets
  assets?: PhishingSimulation[] | EducationModule[]
  clientEnforceDomain: boolean
  selectedDynamicGroups: string[]
}

const LaunchWizardDynamicGroupsTable: FC<LaunchWizardGroupsTableProps> = ({
  handleAddDynamicGroups,
  handleRemoveFromList,
  handleDistributionTargetsChange,
  distributionTargets,
  assets,
  clientEnforceDomain,
  selectedDynamicGroups,
}) => {
  const { t } = useTranslation()
  const { queryParams, updateQueryParam } = useQueryParams(INITIAL_QUERY_FILTERS)

  const { data: queryData, isFetching } = useDynamicGroups({
    ...queryParams,
    check_unverified_domain: true,
  })

  const { results: dynamicGroups, total } = queryData || { total: 0, results: [] }

  const isPackage = assets && assets.length > 1

  const handleFilterChange = useCallback(
    (value: string) => {
      updateQueryParam({ search: value })
    },
    [updateQueryParam]
  )

  //TODO move this to BE
  const isDisabled = (row: RowDataItem) => {
    return row.count === 0
  }

  const rowClick = (row: RowDataItem) => {
    if (isDisabled(row)) return

    if (selectedDynamicGroups.includes(row.id)) {
      handleRemoveFromList(row.id)
    } else {
      handleAddDynamicGroups([row.id], [{ ...(row as DynamicGroup) }])
    }
  }

  const tableColumns = useMemo<TalbeColumn[]>(() => {
    const columns = [
      {
        id: 'custom_checkbox',
        label: '',
        component: (row: DynamicGroup) => (
          <Tooltip
            title={`launchWizard.groupsTable.warnings.has_no_members`}
            placement={'right'}
            disableHoverListener={!isDisabled(row)}>
            <Checkbox
              color="primary"
              checked={selectedDynamicGroups.includes(row.id)}
              inputProps={{
                'aria-labelledby': `table-checkbox-${row.id}`,
              }}
            />
          </Tooltip>
        ),
      },
      {
        id: 'name',
        label: t('dynamicGroups.groupType'),
        component: (row: DynamicGroup) => {
          //TODO Move group warning to BE
          const hasEmailSimulation = assets && assets.some((asset) => asset?.vectors?.includes('email'))
          const onlyAssetsWithPhone = assets?.every((a) =>
            a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
          )

          row.warnings = {}

          if (row.has_unverified_domain && hasEmailSimulation) {
            row.warnings.has_unverified_domain = true
          }
          if (row.has_member_with_missing_phone && onlyAssetsWithPhone) {
            row.warnings.has_missing_phone = true
          }

          return <TalbleCellNameWithWarnings row={row} />
        },
      },
      {
        id: 'count',
        label: t('dynamicGroups.totalMembers'),
      },
      {
        id: 'awareness_score',
        label: t('dynamicGroups.score'),
        component: (row: DynamicGroup) => {
          return <HumanizedScore score={row.awareness_score} />
        },
      },
    ]

    if (isPackage) {
      columns.splice(2, 0, {
        id: 'distribution',
        label: t('dynamicGroups.distribution'),
        component: (row: DynamicGroup) => (
          <DistributionSelect
            type="dynamic_groups"
            onChange={handleDistributionTargetsChange}
            itemId={row.id}
            selectedItems={distributionTargets.dynamic_groups}
            assets={assets}
            disabled={isDisabled(row)}
            disableUnverifiedDomain={row.has_unverified_domain && clientEnforceDomain}
          />
        ),
      })
    }

    return columns
  }, [t, selectedDynamicGroups, dynamicGroups, isPackage])

  return (
    <>
      <div style={{ marginBottom: 1, display: 'flex' }}>
        <SimpleSearchBar onChange={handleFilterChange} />
      </div>
      <Paper sx={{ overflowX: 'scroll', 'scrollbar-width': 'thin' }}>
        <Table
          loading={isFetching}
          data={dynamicGroups}
          total={total}
          inactiveRowCondition={isDisabled}
          disabledPagination
          customSelect
          queryParams={queryParams}
          updateQueryParam={updateQueryParam}
          columns={tableColumns}
          rowClick={rowClick}
          noResultsComponent={<NoResults />}
        />
      </Paper>
    </>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

//TODO Combine this component with Members/Group/Offices
const TalbleCellNameWithWarnings: FC<{ row: DynamicGroup }> = ({ row }) => {
  const warningLink = {
    has_inactive_member: '../../recipients/members',
    has_unverified_domain: '../../settings/allowlisting',
  }

  const warningIcons = {
    has_missing_phone: byPrefixAndName.fak['light-mobile-circle-xmark'],
  }

  return (
    <Box display="flex" textAlign="left" alignItems="center" gap={0.5}>
      <FontAwesomeIcon icon={faAtom as IconProp} color={theme.palette.purple[500]} fontSize={18} />
      {row.name}
      <Tooltip title={row.description} placement="top">
        <FontAwesomeIcon icon={faInfoCircle as IconProp} />
      </Tooltip>
      {Object.keys(row?.warnings).map((key) => (
        <Tooltip
          title={
            <Trans
              i18nKey={`launchWizard.groupsTable.warnings.${key}`}
              components={{
                1: (
                  <Link
                    href={warningLink[key]}
                    target="_blank"
                    style={{
                      textDecoration: 'underline #D1F6FF',
                      color: '#D1F6FF',
                    }}
                  />
                ),
              }}
            />
          }
          placement="top">
          {warningIcons[key] ? (
            <StyledFontAwesome icon={byPrefixAndName.fak['light-mobile-circle-xmark'] as IconProp} />
          ) : (
            <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
          )}
        </Tooltip>
      ))}
    </Box>
  )
}

export default LaunchWizardDynamicGroupsTable
